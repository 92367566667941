import { useCldVideoPlayer } from "./cld-video-context";
import { useCallback } from "react";
import { Button } from "../../components/porsche-design-system/button";
import type { ButtonProps } from "../../components/porsche-design-system/button";
import { ButtonPure } from "../../components/porsche-design-system/button-pure";

type CldVideoToggleMuteProps = Omit<ButtonProps, "aria-label"> & {
    mobile?: boolean;
};

const CldVideoToggleMute = (props: CldVideoToggleMuteProps) => {
    const { mobile, onClick, ...rest } = props;

    const { state, dispatch } = useCldVideoPlayer();

    const handleClick = useCallback(
        (event: React.MouseEvent) => {
            dispatch({ type: state.isMuted ? "UNMUTE" : "MUTE" });
            onClick?.(event);
        },
        [dispatch, state, onClick]
    );

    if (mobile) {
        return (
            <ButtonPure
                onClick={handleClick}
                theme="dark"
                {...rest}
                hideLabel={true}
                icon={state.isMuted ? "volume-off" : "volume-up"}
                aria-label={state.isMuted ? "unmute video" : "mute video"}
                border="2px solid var(--colors-allWhite)"
                borderRadius="var(--radii-small)"
                size="x-large"
            />
        );
    }

    return (
        <Button
            onClick={handleClick}
            theme="dark"
            variant="secondary"
            {...rest}
            hideLabel={true}
            icon={state.isMuted ? "volume-off" : "volume-up"}
            aria-label={state.isMuted ? "unmute video" : "mute video"}
        />
    );
};
CldVideoToggleMute.displayName = "CldVideoToggleMute";

export { CldVideoToggleMute };
