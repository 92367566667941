import { useSwiper } from "swiper/react";
import { Flex } from "../../chakra-ui/flex";
import type { FlexProps } from "../../chakra-ui/flex";
import type { MouseEvent } from "react";

type CarouselPaginationProps = FlexProps & {
    activeSlideIndex: number;
};

const CarouselPagination = ({ activeSlideIndex, onClick, ...rest }: CarouselPaginationProps) => {
    const swiper = useSwiper();

    const getAriaLabel = (isActiveSlide: boolean, snapIndex: number) => {
        if (isActiveSlide) {
            return "current slide";
        } else if (snapIndex > activeSlideIndex) {
            return "forward";
        } else {
            return "backward";
        }
    };

    return swiper.snapGrid.length >= 2 ? (
        <Flex padding={1} gap={2} {...rest}>
            {swiper.snapGrid.map((_snapGrid, snapIndex) => {
                const isActiveSlide = activeSlideIndex === snapIndex;
                return (
                    <Flex
                        as="button"
                        type="button"
                        onClick={(e: MouseEvent<HTMLDivElement>) => {
                            swiper.slideTo(snapIndex);
                            onClick?.(e);
                        }}
                        key={crypto.randomUUID()}
                        height={2}
                        width={isActiveSlide ? 5 : 2}
                        bgColor={isActiveSlide ? "porscheBlack" : "grey700"}
                        borderRadius="small"
                        transitionDuration="var(--transition-duration-moderate)"
                        transitionTimingFunction="background-color, width"
                        aria-label={getAriaLabel(isActiveSlide, snapIndex)}
                        aria-hidden={false}
                    />
                );
            })}
        </Flex>
    ) : null;
};

export { CarouselPagination };
